import {
  BrowserProvider,
  FallbackProvider,
  JsonRpcProvider,
  JsonRpcSigner,
} from "ethers";
import {
  CONNECTED,
  CONTENTS,
  DISCONNECTED,
  LOGIN,
  WEB3_BALANCE,
  WEB3_BURN_AMOUNT,
  WEB3_CLEAR_ERRORS,
  WEB3_CONNECTING,
  WEB3_CONVERSION_ALLOWANCE,
  WEB3_GAS_PRICE,
  WEB3_GXP_REQUIREMENTS,
  WEB3_HAS_WALLET,
  WEB3_METAPORT_MODAL,
  WEB3_NEEDS_GXP,
  WEB3_NETWORK_MODAL,
  WEB3_RECENT_DEPOSITS,
  WEB3_RECENT_TRANSACTIONS,
  WEB3_RECENT_WITHDRAWALS,
  WEB3_SAVE_ADDRESS,
  WEB3_TOTAL_STAKED,
  WEB3_WALLET_ALLOW_INFO,
  WEB3_WALLET_COUNT,
  WEB3_WALLET_CREATE,
  WEB3_WALLET_ERROR,
  WEB3_WALLET_GASCOST,
  WEB3_WALLET_SEND_INFO,
} from "redux/types";
import {
  clearBearerToken,
  getBearerToken,
  getCurrentUser,
  getsFUEL,
  myNFTs,
  refreshInstance,
  setBearerToken,
  signIn,
} from "utils/api";
import { sleep } from "utils/time";
import * as Web3Call from "utils/web3/evm.js";
import * as Web3Utils from "utils/web3/web3Utils.js";

// export const autoConnect = () => (dispatch) =>
//   Web3Call.checkConnection().then((res) => {
//     dispatch({
//       type: CONNECTED,
//       address: res.address,
//       chainId: res.chainId,
//     });
//   });

function clientToProvider(client) {
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  if (transport.type === "fallback") {
    const providers = transport.transports.map(
      ({ value }) => new JsonRpcProvider(value?.url, network)
    );
    if (providers.length === 1) return providers[0];
    return new FallbackProvider(providers);
  }
  return new JsonRpcProvider(transport.url, network);
}
function clientToSigner(client) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new BrowserProvider(transport, network);
  const signer = new JsonRpcSigner(provider, account.address);
  return signer;
}

// Generic web3 actions
export const connect = (address, client) => async (dispatch) => {
  let provider = clientToProvider(client);
  let signer = clientToSigner(client);
  if (!provider) {
    return;
  }
  try {
    Web3Call.connect(address, provider, signer);
    dispatch({ type: WEB3_CONNECTING, status: true });
    let chainId = await client.getChainId();

    if (!address) {
      dispatch({ type: WEB3_CONNECTING, status: false });
      return;
    }
    dispatch({
      type: CONNECTED,
      address,
      chainId,
    });

    let bearerToken = getBearerToken();
    let user = false;

    if (bearerToken) {
      // TODO: hide error for bad token from the toasts.
      try {
        const res = await getCurrentUser();
        user = res.data.data.user;
        if (user?.account_address !== address.toLowerCase()) {
          clearBearerToken();
          refreshInstance();
          bearerToken = false;
        } else {
          dispatch({ type: LOGIN, user });
          dispatch({ type: WEB3_CONNECTING, status: false });
          return;
        }
      } catch (e) {
        console.log(e);
        clearBearerToken();
        refreshInstance();
        bearerToken = false;
      }
    }
    try {
      if (!bearerToken) {
        let timestamp = Date.now();
        let signature = await Web3Utils.accountAuth(timestamp);
        let res = await signIn({ signature, timestamp });
        if (res) {
          user = res.data.data.user;
          if (user._id) {
            setBearerToken(res.data.token);
            refreshInstance();
            dispatch({
              type: LOGIN,
              user,
            });
          }
        }
      } else if (user) {
        dispatch({ type: LOGIN, user });
      }
    } catch (e) {
      console.log(e);
    }
  } catch (e) {
    console.log(e);
  }
  dispatch({ type: WEB3_CONNECTING, status: false });
};

export const showMetaport = (show) => (dispatch) => {
  dispatch({ metaport: show, type: WEB3_METAPORT_MODAL });
};

export const showNetworkModal = (show) => (dispatch) => {
  dispatch({ type: WEB3_NETWORK_MODAL, showNetworkModal: show });
};

export const getUserCoins =
  (settings, fetchAllowance = true) =>
  async (dispatch) => {
    const allowances = {};
    const { payment } = settings;

    let promises = [Web3Call.getBalanceOf(false), Web3Call.getBalanceOf(payment.address)];
    if (fetchAllowance && settings.market.address) {
      promises.push(Web3Call.getAllowance(payment.address, settings.market.address));
    }

    if (payment.address) {
      const [gasBalance, balance, allowance] = await Promise.all(promises);
      if (allowance) {
        allowances[settings.market.address] = allowance.toString();
      }
      dispatch({
        type: WEB3_BALANCE,
        allowances,
        balance: balance.toString(),
        gasBalance: gasBalance.toString(),
      });
    }
  };

export const getsFuel = () => async (dispatch) => {
  let res = await getsFUEL();
  if (res?.data) {
    let gasBalance = "0";
    let retries = 0;
    while (gasBalance == "0" && retries < 5) {
      await sleep(2000);
      gasBalance = (await Web3Call.getBalanceOf()).toString();
      retries++;
    }
    dispatch({ type: WEB3_BALANCE, gasBalance });
  }
};

export const getContents =
  ({ page }) =>
  async (dispatch) => {
    let newData = await myNFTs(page);
    let { nfts, total, hasNextPage, perPage } = newData.data;

    dispatch({
      type: CONTENTS,
      data: nfts,
      nextPage: page + 1,
      perPage,
      total,
      hasNextPage,
    });
  };

const web3Reducer = (
  state = {
    address: false,
    allowances: {},
    balance: 0,
    gasBalance: 0,
    connected: false,
    connecting: false,
    bad_network: false,
    showNetworkModal: false,
    nft_contents: {
      data: [],
      nextPage: 1,
      total: 0,
      perPage: 25,
      hasNextPage: false,
    },
    payment: {},
    metaport: false,
  },
  action
) => {
  switch (action.type) {
    case CONTENTS: {
      if (action.data?.length > 0) {
        return {
          ...state,
          nft_contents: { ...action },
        };
      }
      return {
        ...state,
        nft_contents: { ...action, data: [] },
      };
    }
    case WEB3_CONNECTING: {
      return { ...state, connecting: action.status };
    }
    case CONNECTED: {
      if (action.chainId) {
        const bad_network = !Web3Utils.isValidNetwork(action.chainId);
        return {
          ...state,
          connected: true,
          connecting: false,
          address: action.address || state.address,
          chainId: action.chainId,
          bad_network,
        };
      } else {
        return {
          ...state,
          connected: true,
          connecting: false,
          address: action.address || state.address,
          chainId: action.chainId || state.chainId,
          bad_network: false,
        };
      }
    }
    case DISCONNECTED: {
      return {
        ...state,
        connected: false,
        connecting: false,
        address: null,
        chainId: null,
        bad_network: false,
      };
    }
    case WEB3_BALANCE: {
      return {
        ...state,
        balance: action.balance || state.balance,
        gasBalance: action.gasBalance || state.gasBalance,
        allowances: { ...state.allowances, ...action.allowances },
      };
    }
    case WEB3_METAPORT_MODAL: {
      return { ...state, metaport: action.metaport };
    }
    case WEB3_NETWORK_MODAL: {
      return { ...state, showNetworkModal: action.showNetworkModal };
    }
    case WEB3_GAS_PRICE: {
      return { ...state, gasPrice: action.gasPrice };
    }
    case WEB3_RECENT_DEPOSITS: {
      return { ...state, recentDeposits: action.recentDeposits };
    }
    case WEB3_RECENT_WITHDRAWALS: {
      return { ...state, recentWithdrawals: action.recentWithdrawals };
    }
    case WEB3_RECENT_TRANSACTIONS: {
      return {
        ...state,
        recentTransactions: action.recentTransactions,
        totalDeposits: action.totalDeposits,
        totalWithdrawals: action.totalWithdrawals,
      };
    }
    case WEB3_BURN_AMOUNT: {
      return {
        ...state,
        burnAmount: action.amount,
      };
    }
    case WEB3_WALLET_SEND_INFO: {
      return {
        ...state,
        tapWalletSendGasLimit: action.tapWalletSendGasLimit,
      };
    }
    case WEB3_WALLET_ALLOW_INFO: {
      return {
        ...state,
        tapWalletAllowGasLimit: action.tapWalletAllowGasLimit,
      };
    }
    case WEB3_CONVERSION_ALLOWANCE: {
      return {
        ...state,
        conversionAllowance: action.conversionAllowance,
      };
    }
    case WEB3_WALLET_COUNT: {
      return {
        ...state,
        tapWalletCount: action.tapWalletCount,
      };
    }
    case WEB3_WALLET_GASCOST: {
      return {
        ...state,
        tapWalletGasLimit: action.tapWalletGasLimit,
      };
    }
    case WEB3_WALLET_CREATE: {
      return {
        ...state,
        newTapWallet: action.newTapWallet,
      };
    }
    case WEB3_SAVE_ADDRESS: {
      return {
        ...state,
        address: action.address,
      };
    }
    case WEB3_HAS_WALLET: {
      return {
        ...state,
        newTapWallet: action.tapWallet,
      };
    }
    case WEB3_WALLET_ERROR: {
      return {
        ...state,
        walletError: action.walletError,
      };
    }
    case WEB3_CLEAR_ERRORS: {
      return {
        ...state,
        walletError: false,
        keyError: false,
      };
    }
    case WEB3_TOTAL_STAKED: {
      return {
        ...state,
        totalStaked: action.totalStaked,
      };
    }
    case WEB3_GXP_REQUIREMENTS: {
      return {
        ...state,
        GXPRequirements: action.GXPRequirements,
      };
    }
    case WEB3_NEEDS_GXP: {
      return {
        ...state,
        needsGXP: action.needsGXP,
      };
    }
    default: {
      return state;
    }
  }
};

export default web3Reducer;
