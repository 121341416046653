import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { initFormVals } from "redux/state/initialValues";
import { Field, FieldArray, reduxForm, SubmissionError } from "redux-form";
import { searchGames, updateGame, uploadGameImage } from "utils/api";
import { renderFormV2 } from "utils/form";

import LoadingButton from "components/button/LoadingButton";
import MultiSelectAccordion from "components/inputs/MultiSelectAccordion";
import ToastPopup from "components/utils/ToastPopup";
import Tooltip from "components/utils/Tooltip";

export const formName = "game-review";

const ReviewGameModal = ({ onSubmit, game, onHide, ...props }) => {
  if (!game) {
    return false;
  }

  const dispatch = useDispatch();
  const { handleSubmit, pristine, submitting, reset } = props;
  const [logoImage, setLogoImage] = useState(game.logo_image || null);
  const [bannerImage, setBannerImage] = useState(game.banner_image || null);
  const { _id: id } = game;
  const genres = ["action", "adventure", "puzzle"];
  const platforms = ["desktop", "mobile", "web"];
  const playInfo = ["singleplayer", "multiplayer", "cooperative"];
  const tags = useSelector((state) => state.settings?.tags);

  useEffect(() => {
    dispatch(initFormVals(formName, game));
  }, []);

  const collectionsData = {
    title: "Link Collections",
    name: "linked_collections",
    show: true,
    values: game.linked_collections,
    Tooltip: () => (
      <Tooltip>
        <>
          Select all collections that are related to or interact with your game in some
          way. These collections will appear on the Game Details page and will be used to
          calculate game statistics such as total volume sold.
        </>
      </Tooltip>
    ),
  };

  const genresData = {
    title: "Genres",
    name: "genres",
    show: true,
    values: genres,
  };

  const platformsData = {
    title: "Platforms",
    name: "platforms",
    show: true,
    values: platforms,
  };

  const playInfoData = {
    title: "Play Info",
    name: "play_info",
    show: true,
    values: playInfo,
  };

  const submit = async (values) => {
    const nameTaken = await searchGames({ name: values.name }).then(
      (res) => res.data.results[0]
    );
    if (nameTaken && nameTaken._id !== id) {
      throw new SubmissionError({
        name: "Name taken",
        _error: "A game with that name already exists",
      });
    }
    const updatedGame = await updateGame(id, values);
    if (updatedGame.data) {
      const token = updatedGame.data.accessToken;
      const images = {};
      if (values.banner_img) {
        await uploadGameImage(token, id, values.banner_img, "featured")
          .then((res) => (images.banner_image = res))
          .catch(() => {
            ToastPopup("An error occurred uploading Game Banner.", "error");
          });
      }
      if (values.logo_img) {
        await uploadGameImage(token, id, values.logo_img, "logo")
          .then((res) => (images.logo_image = res))
          .catch(() => {
            ToastPopup("An error occurred uploading Game Logo.", "error");
          });
      }
      if (images) await updateGame(id, images);
      // TODO: show "success" popup?
      ToastPopup("Game successfully updated.", "success");
    }
  };

  if (!game) {
    return false;
  }

  return (
    <Modal
      show={game}
      onHide={() => {
        reset();
        onHide();
      }}
      size="xl"
    >
      <Modal.Header closeButton></Modal.Header>
      {/*If collection is mysten, there is no transfer_policy, make one*/}

      <div className="modal-body space-y-20 pd-40">
        <div className="tf-list-item tf-section">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12">
                <div className="flat-form flat-form-wide">
                  <div className="flat-tabs tab-list-item">
                    <Link to={`/games/${game?._id}`}>
                      <button>View Game</button>
                    </Link>
                    <form onSubmit={handleSubmit(submit)}>
                      <Field
                        type="file"
                        name="banner_img"
                        title="Banner Image"
                        className="hideInput"
                        labelClassName="banner"
                        imgClassName="inheritHeight"
                        featuredImage={bannerImage}
                        component={renderFormV2}
                        containername="required"
                        accept=".png,.jpg,.jpeg,.gif,.mp4"
                        onChange={(e) => {
                          if (e) {
                            setBannerImage(e);
                          }
                        }}
                      />
                      <Field
                        type="file"
                        name="logo_img"
                        title="Logo Image"
                        className="hideInput"
                        labelClassName="square"
                        imgClassName="inheritHeight"
                        featuredImage={logoImage}
                        component={renderFormV2}
                        containername="required"
                        accept=".png,.jpg,.jpeg,.gif"
                        onChange={(e) => {
                          if (e) {
                            setLogoImage(e);
                          }
                        }}
                      />
                      <MultiSelectAccordion
                        item={collectionsData}
                        formName={formName}
                        image={true}
                        fullwidth={true}
                      />
                      <Field
                        name="media_url"
                        title="Featured Media URL"
                        type="text"
                        placeholder="This can be a Youtube Video URL, an mp4, or an image."
                        component={renderFormV2}
                        required
                      />
                      <Field
                        name="name"
                        title="Game Title"
                        type="text"
                        placeholder="e.g. Sui 2048"
                        component={renderFormV2}
                        required
                      />
                      <Field
                        name="description"
                        type="textarea"
                        placeholder='e.g. "This is my game!"'
                        component={renderFormV2}
                        required
                      />
                      <Field
                        name="developer"
                        type="text"
                        placeholder='e.g. "Bamboo Games"'
                        component={renderFormV2}
                        required
                      />

                      <MultiSelectAccordion item={platformsData} formName={formName} />
                      <MultiSelectAccordion item={genresData} formName={formName} />
                      <MultiSelectAccordion item={playInfoData} formName={formName} />
                      <Field
                        name="twitter"
                        type="text"
                        placeholder="https://twitter.com/"
                        component={renderFormV2}
                      />
                      <Field
                        name="discord"
                        type="text"
                        placeholder="https://discord.gg/"
                        component={renderFormV2}
                      />
                      <Field name="website" type="text" component={renderFormV2} />
                      <Field name="game_url" type="text" component={renderFormV2} />
                      <MultiSelectAccordion
                        item={{ name: "tags", title: "Tags", show: true, values: tags }}
                        formName={formName}
                      />

                      <Field type="checkbox" name="active" component={renderFormV2} />
                      <Field type="checkbox" name="featured" component={renderFormV2} />
                      <LoadingButton type="submit" disabled={pristine || submitting}>
                        Update Game
                      </LoadingButton>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(reduxForm({ form: formName, enableReinitialize: true })(ReviewGameModal));
