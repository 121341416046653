import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { searchCollections, updateCollection } from "utils/api";
import { sortDropdownBasic } from "utils/sortDropdowns";

import LayoutButtons from "components/button/LayoutButtons";
import RefreshButton from "components/button/RefreshButton";
import ListCollectionCard from "components/cards/ListCollectionCard";
import SearchBar from "components/inputs/SearchBar";
import Sorting from "components/inputs/Sorting";
import CollectionSkeleton from "components/layouts/collections/CollectionSkeleton";
import ListCollectionSkeleton from "components/layouts/collections/ListCollectionSkeleton";
import CardList from "components/layouts/explore/Lists/CardList";
import ReviewCollectionModal from "components/layouts/modal/review/ReviewCollectionModal";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import ReviewCollectionCard from "components/layouts/review/ReviewCollectionCard";
import { useRefreshSearch } from "components/utils/SearchProvider";
import ToastPopup from "components/utils/ToastPopup";

const ReviewCollectionsPanel = ({ title }) => {
  const loading = useSelector((state) => state.search.loading);
  const { refreshSearch, setSortParams } = useRefreshSearch();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [layout, setLayout] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const reduxState = useSelector((state) => state.search.collections);
  const orderedHeaders = [
    "Name",
    "Floor",
    "Volume",
    "Total Sales",
    "Active Supply",
    "Favorites",
  ];

  const submit = async (values) => {
    const nameTaken = await searchCollections({ name: values.name }).then(
      (res) => res.data.results[0]
    );
    if (nameTaken && nameTaken._id !== values._id) {
      throw new SubmissionError({
        name: "Name taken",
        _error: "A collection with that name already exists",
      });
    }

    const updatedCollection = await updateCollection(values._id, values);
    if (updatedCollection.data) {
      ToastPopup("Collection successfully updated.", "success");
    }
  };

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <div className="view">
          <div className="flex">
            <RefreshButton refreshHandler={refreshSearch} loading={loading} />
            <LayoutButtons layout={layout} setLayout={setLayout} />
          </div>
          <Sorting setSortParams={setSortParams} list={sortDropdownBasic} />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <CardList
          layout={layout}
          title={title}
          setModalShow={setModalShow}
          setCount={setCount}
          setPages={setPages}
          SkeletonComponent={CollectionSkeleton}
          CardComponent={ReviewCollectionCard}
          ListSkeletonComponent={ListCollectionSkeleton}
          ListCardComponent={ListCollectionCard}
          orderedHeaders={orderedHeaders}
          reduxState={reduxState}
          onClick={setModalShow}
        />
      </PaginationWrapper>
      <ReviewCollectionModal
        onSubmit={submit}
        onHide={() => setModalShow(null)}
        collection={modalShow}
      />
    </div>
  );
};

export default ReviewCollectionsPanel;
