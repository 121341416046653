import IERC20Artifact from "ethereum_abi/IERC20.json";
import IERC721Artifact from "ethereum_abi/IERC721.json";
import Market721Artifact from "ethereum_abi/Market721.json";
import { ContractFactory, JsonRpcProvider } from "ethers";
import { Explorer, IS_TESTNET } from "utils/environments";
import { sleep } from "utils/time";
// import Authereum from "authereum";
// import { Bitski } from "bitski";
// import ethProvider from "eth-provider";
// import LogsDecoder from "logs-decoder";
import Web3 from "web3";

// import Web3Modal from "web3modal";
import { ZERO_ADDRESS } from "./tokenInfo";
import { parseNetworkId, toBaseUnit } from "./web3Utils";

let network = process.env.REACT_APP_NETWORK; // IS_PROD   ? "https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague"  : "https://testnet.skalenodes.com/v1/giant-half-dual-testnet";
let network_id = process.env.REACT_APP_NETWORK_ID; // IS_PROD ? 1564830818 : 1351057110;
let network_name = process.env.REACT_APP_NETWORK_NAME;

let web3Provider;
let web3Signer;
let web3Connected = false;

web3Provider = new Web3.providers.HttpProvider(network);
let web3 = new Web3(web3Provider);

let currentAddress = 0;

export const getWeb3 = () => web3;

let contractProvider = new JsonRpcProvider(network);
const Contracts = {
  IERC20: new ContractFactory(
    IERC20Artifact.abi,
    IERC20Artifact.bytecode,
    contractProvider
  ),
  IERC721: new ContractFactory(
    IERC721Artifact.abi,
    IERC721Artifact.bytecode,
    contractProvider
  ),
  Market: new ContractFactory(
    Market721Artifact.abi,
    Market721Artifact.bytecode,
    contractProvider
  ),
};

export const avgBlocktime = 15;
const setProviders = () => {
  try {
    Object.keys(Contracts).forEach((key) => {
      Contracts[key] = Contracts[key].connect(web3Signer);
    });
  } catch (e) {
    console.log(e);
  }
  web3Connected = true;
};

// Wrapper stuff for web3 to use promises
const promisify = (inner) =>
  new Promise((resolve, reject) =>
    inner((err, res) => {
      if (err) {
        reject(err);
      }
      resolve(res);
    })
  );

export const isConnected = () => web3Connected;

export const checkConnection = async () => {
  // Check if User is already connected by retrieving the accounts
  const addresses = await web3.eth?.getAccounts();
  const chainId = await web3.eth?.getChainId();
  if (Array.isArray(addresses)) {
    if (addresses.length === 0) {
      let accounts = await web3.eth.requestAccounts();
      return { chainId: parseNetworkId(chainId), address: accounts[0] };
    }
    [currentAddress] = addresses;
    return { address: addresses[0], chainId: parseNetworkId(chainId) };
  }
  const address = addresses;
  return { address, chainId: parseNetworkId(chainId) };
};

export const connect = async (account, provider, signer) => {
  currentAddress = account;
  web3Signer = signer;
  web3Provider = provider;
  setProviders();
};

export const disconnect = async () => {
  // TODO: Which providers have close method?
  if (web3Provider.close) {
    await web3Provider.close();

    // If the cached provider is not cleared,
    // WalletConnect will default to the existing session
    // and does not allow to re-scan the QR code with a new wallet.
    // Depending on your use case you may want or want not his behavior.
    web3Provider = null;
  }
};

// // listeners for address, network changes
// /**
//  * Listens for changes in the address the user is currently using
//  * @param {*} listener
//  */
// export const networkAddressChange = (listener, dispatch) => {
//   if (typeof web3Provider.on === "function") {
//     web3Provider.on("accountsChanged", (addresses) => {
//       [currentAddress] = addresses;
//       dispatch(listener(addresses[0]));
//     });
//   }
// };

/**
 * Listens for changes in the network the user is currently using
 * @param {*} listener
 */
export const networkChange = (listener, dispatch) => {
  if (typeof web3Provider.on === "function") {
    web3Provider.on("chainChanged", (chainId) => {
      dispatch(
        listener(
          chainId.includes("0x") ? parseInt(chainId.replace("0x", ""), 16) : chainId
        )
      );
    });
  }
};

export const selectedAddress = () => {
  if (currentAddress) return currentAddress;
  if (web3Provider.selectedAddress) return web3Provider.selectedAddress;
  if (web3Provider.address) return web3Provider.address;
  if (web3Provider.accounts?.[0]) return web3Provider.accounts?.[0];
  return false;
};

const getEthBalance = (account) => web3.eth.getBalance(account);
export const getGasPrice = () => promisify((cb) => web3.eth.getGasPrice(cb));
export const getBlockByNumber = (number) =>
  promisify((cb) => web3.eth.getBlock(number, cb));
export const getTransaction = (hash) =>
  promisify((cb) => web3.eth.getTransaction(hash, cb));
export const getTransactionReceipt = (hash) => web3.eth.getTransactionReceipt(hash);

export const getTransactionReceiptMined = (txHash, interval) => {
  const transactionReceiptAsync = (resolve, reject) => {
    web3.eth.getTransactionReceipt(txHash, (error, receipt) => {
      if (error) {
        reject(error);
      } else if (receipt == null) {
        setTimeout(() => transactionReceiptAsync(resolve, reject), interval || 10000);
      } else {
        resolve(receipt);
      }
    });
  };
  if (typeof txHash === "string") {
    return new Promise(transactionReceiptAsync);
  }
  throw new Error("Invalid Type: " + txHash);
};

// ERC20 functions
/**
 * Gets the user's current balance for an ERC20 token
 * @param {string} tokenAddress
 * @param {string} userAddress
 * @returns {Promise}
 */
export const getAllowance = async (token, to, from) => {
  const instance = Contracts.IERC20.attach(token);
  const amount = await instance.allowance(from || selectedAddress(), to);
  return amount;
};

export const approveFor = async (
  tokenAddress,
  toAddress,
  from,
  amount = "10000000000000000000000000000"
) => {
  const erc20 = Contracts.IERC20.attach(tokenAddress);
  return new Promise((resolve, reject) => {
    erc20
      .getFunction("approve")
      .staticCall(toAddress, amount)
      .then(() => {
        erc20
          .approve(toAddress, amount, { from: from || selectedAddress() })
          .then(({ hash }) => {
            resolve(hash);
          })
          .catch("error", (error) => {
            reject(error);
          });
      })
      .catch(async (e) => {
        console.log(e);
        const erc721 = Contracts.IERC721.attach(tokenAddress);
        erc721
          .setApprovalForAll(toAddress, true, { from: from || selectedAddress() })
          .then(({ hash }) => {
            resolve(hash);
          })
          .catch("error", (error) => {
            reject(error);
          });
      });
  });
};

export const getBalanceOf = async (tokenAddress, userAddress) => {
  if (!tokenAddress || tokenAddress === ZERO_ADDRESS) {
    return getEthBalance(userAddress || selectedAddress());
  }

  const instance = Contracts.IERC20.attach(tokenAddress);
  return instance.balanceOf(userAddress || selectedAddress());
};

// ERC721 functions
export const getTokenMetaDataURL = async (address, tokenId) => {
  var contract = Contracts.IERC721.attach(address);
  // use axios to call this URL and fetch token info as a JSON response
  return contract.tokenURI(tokenId);
};

export const getERC721Approval = async (address, tokenId) => {
  const contract = Contracts.IERC721.attach(address);

  return contract.getApproved(tokenId);
};

/**
 * Fetches a single NFT from the market.
 * @param {uint256} tokenId
 * @returns {Object} listing
 */
export const fetchTokenSale = async (marketContract, tokenId) => {
  const market = Contracts.Market.attach(marketContract);
  const tokenSale = await market._tokenSales(tokenId);
  const matchedResponse = {
    ...tokenSale,
    id: tokenId,
  };
  return matchedResponse;
};

export const updateRoyaltyFee = async (marketContract, contract, owner, saleCut) => {
  const market = Contracts.Market.attach(marketContract);
  return market.setContractOwnerInfo(contract, owner, parseInt(saleCut * 100), owner);
};

/**
 * Buys something for sale from the Contracts.Market.
 * @param {uint256} saleId
 * @param {uint256} amount
 * @param {string} from
 */
export const buyFromMarket = async (listing, from) => {
  let market = Contracts.Market.attach(listing.market.marketAddress);
  return market.buy(listing.market.marketIndex, listing.bn_sale_price, { from });
};

export const callMarket = async (address, func, params) => {
  let market = Contracts.Market.attach(address);
  return market[func](...params);
};

// TODO: check approval, format price accounting for decimals
export const sellOnMarket = async (
  marketContract,
  marketId,
  tokenContract,
  tokenId,
  payment,
  price,
  from,
  approved = false
) => {
  if (!approved) {
    let nfts = Contracts.IERC721.attach(tokenContract);
    let res = await nfts.approve(marketContract, tokenId, {
      from,
    });
    if (!res) {
      return false;
    }
    await sleep(5000);
  }

  let market = Contracts.Market.attach(marketContract);

  let realPrice = toBaseUnit(price, payment.decimals);

  return market.sell(marketId, tokenId, tokenContract, payment.address, realPrice, {
    from,
  });
};

export const cancelListing = async (marketContract, listingId, from) => {
  const market = Contracts.Market.attach(marketContract);
  return market.cancel(listingId, {
    from,
  });
};
