import React, { useState } from "react";

import CollectionCard from "components/cards/CollectionCard";
import CollectionSkeleton from "components/layouts/collections/CollectionSkeleton";
import CardList from "components/layouts/explore/Lists/CardList";

const GameCollectionsPanel = ({ title, game }) => {
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);

  return (
    <div style={{ marginTop: "2rem" }}>
      <CardList
        title={title}
        setCount={setCount}
        setPages={setPages}
        SkeletonComponent={CollectionSkeleton}
        CardComponent={CollectionCard}
        reduxState={{ count, results: game?.linked_collections || [], pageLimit: 32 }}
        noResultsCustomTitle="Just around the corner..."
        noResultsCustomSubtitle="Stay tuned to discover new collections!"
      />
    </div>
  );
};

export default GameCollectionsPanel;
