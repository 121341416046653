import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { searchCollections } from "redux/state/search";
import { ListGrid } from "utils/styled";

import CollectionCard from "components/cards/CollectionCard";

import CollectionSkeleton from "./CollectionSkeleton";

const PopularCollection = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.search.collections.results);
  const [visible, setVisible] = useState(12);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchCollections({
        review_status: "approved",
        logo_image: { ne: null },
        sortParams: { favorites: -1 },
      })
    ).then(() => {
      setTimeout(() => setLoading(false), 300);
    });
  }, []);

  return (
    <section className="tf-section popular-collections">
      <div className="themesflat-container">
        <div className="heading-live-auctions">
          <h2 className="tf-title pb-22 text-left">Popular Collections</h2>
          <Link to="/explore/collections">
            <div className="border-button">
              <span className="exp">VIEW ALL</span>
            </div>
          </Link>
        </div>
        <ListGrid>
          {loading
            ? Array.from({ length: visible }, (_, index) => {
                return (
                  <CollectionSkeleton key={`popular-collection-skeleton-${index}`} />
                );
              })
            : data
                .slice(0, visible)
                .map((collection) => (
                  <CollectionCard item={collection} key={collection._id} />
                ))}
        </ListGrid>
      </div>
    </section>
  );
};

export default PopularCollection;
