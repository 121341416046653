import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { sortCollectionsDropdown } from "utils/sortDropdowns";

import LayoutButtons from "components/button/LayoutButtons";
import RefreshButton from "components/button/RefreshButton";
import CollectionCard from "components/cards/CollectionCard";
import ListCollectionCard from "components/cards/ListCollectionCard";
import SearchBar from "components/inputs/SearchBar";
import Sorting from "components/inputs/Sorting";
import CollectionSkeleton from "components/layouts/collections/CollectionSkeleton";
import ListCollectionSkeleton from "components/layouts/collections/ListCollectionSkeleton";
import CardList from "components/layouts/explore/Lists/CardList";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import { useRefreshSearch } from "components/utils/SearchProvider";
import { useSidebar } from "components/utils/SidebarProvider";

const CollectionsPanel = ({ title }) => {
  const loading = useSelector((state) => state.search.loading);
  const reduxState = useSelector((state) => state.search.collections);
  const settings = useSelector((state) => state.settings);
  const { setSidebarData } = useSidebar();
  const { refreshSearch, setSortParams } = useRefreshSearch();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(null);
  const [layout, setLayout] = useState(false);
  const orderedHeaders = [
    "Name",
    "Floor",
    "Volume",
    "Total Sales",
    "Active Supply",
    "Favorites",
  ];

  useEffect(() => {
    if (settings) {
      const newSidebarData = [
        {
          title: "Categories",
          name: "collection.tags",
          show: true,
          content: {
            type: "select",
            values: settings.tags,
          },
        },
        {
          title: "Number of Items",
          name: "sale_price",
          content: {
            type: "number",
          },
        },
        {
          title: "Floor Price",
          name: "floor",
          content: {
            type: "number",
          },
        },
      ];
      setSidebarData(newSidebarData);
    }
  }, [settings]);

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <div className="view">
          <div className="flex">
            <RefreshButton refreshHandler={refreshSearch} loading={loading} />
            <LayoutButtons layout={layout} setLayout={setLayout} />
          </div>
          <Sorting setSortParams={setSortParams} list={sortCollectionsDropdown} />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <CardList
          layout={layout}
          title={title}
          setCount={setCount}
          setPages={setPages}
          SkeletonComponent={CollectionSkeleton}
          CardComponent={CollectionCard}
          ListSkeletonComponent={ListCollectionSkeleton}
          ListCardComponent={ListCollectionCard}
          orderedHeaders={orderedHeaders}
          reduxState={reduxState}
        />
      </PaginationWrapper>
    </div>
  );
};

export default CollectionsPanel;
